import React from "react";

import { useGlobalContext } from "./context/globalContext";

const Hero = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <div id="home" className="section">
      <div className="desktopview">
        <img
          onClick={handleShowModal}
          height="730"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/lntevaraheightsweb.webp"
          alt="L&T Evara Heights Thane Web Banner, L&T Evara Heights Web Banner"
        />
      </div>
      <div className="mobileview">
        <img
          onClick={handleShowModal}
          height="100%"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/lntevaraheightsweb.webp"
          alt="L&T Evara Heights Thane Mobile Banner, L&T Evara Heights Mobile Banner"
        />
      </div>
    </div>
  );
};

export default Hero;
























// import React, { useState, useEffect } from "react";
// import { useGlobalContext } from "./context/globalContext";

// const Hero = () => {
//   const { handleShowModal } = useGlobalContext();
//   const [currentSlide, setCurrentSlide] = useState(0);

//   // Auto slide every 4 seconds
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentSlide((prev) => (prev + 1) % 3);
//     }, 4000);

//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <div id="home" className="section">
//       <div className="desktopview">
//         <div className="hero-slider-container">
//           <div className="hero-slider-track" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
//             {[1, 2, 3].map((_, index) => (
//               <img
//                 key={index}
//                 onClick={handleShowModal}
//                 height="730"
//                 width="100%"
//                 className="hero-slider-image"
//                 src="images/bhandup/lntevaraheightsweb.jpeg"
//                 alt={`L&T Evara Heights Thane Web Banner ${index + 1}`}
//               />
//             ))}
//           </div>
//           <div className="hero-slider-dots">
//             {[0, 1, 2].map((index) => (
//               <button
//                 key={index}
//                 className={`hero-slider-dot ${currentSlide === index ? 'active' : ''}`}
//                 onClick={() => setCurrentSlide(index)}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="mobileview">
//         <img
//           onClick={handleShowModal}
//           height="100%"
//           width="100%"
//           className="d-block micro-main-slider-img ls-is-cached lazyloaded"
//           src="images/bhandup/lntevaramob.jpeg"
//           alt="L&T Evara Heights Thane Mobile Banner"
//         />
//       </div>
//     </div>
//   );
// };

// export default Hero; 










// import React, { useState, useEffect } from "react";
// import { useGlobalContext } from "./context/globalContext";

// const Hero = () => {
//   const { handleShowModal } = useGlobalContext();
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev + 1) % 3);
//   };

//   const handleSlideChange = (index) => {
//     // Only allow moving forward
//     if (index > currentSlide || (currentSlide === 2 && index === 0)) {
//       setCurrentSlide(index);
//     }
//   };

//   // Auto slide every 4 seconds
//   useEffect(() => {
//     const timer = setInterval(nextSlide, 4000);
//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <div id="home" className="section">
//       <div className="desktopview">
//         <div className="hero-slider-container">
//           <div className="hero-slider-track" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
//             {[1, 2, 3].map((_, index) => (
//               <img
//                 key={index}
//                 onClick={handleShowModal}
//                 height="730"
//                 width="100%"
//                 className="hero-slider-image"
//                 src="images/bhandup/lntevaraheightsweb.jpeg"
//                 alt={`L&T Evara Heights Thane Web Banner ${index + 1}`}
//               />
//             ))}
//           </div>
//           <button 
//             className="hero-slider-arrow prev" 
//             onClick={() => handleSlideChange((currentSlide + 2) % 3)}
//           >
//             &#8249;
//           </button>
//           <button 
//             className="hero-slider-arrow next" 
//             onClick={() => handleSlideChange((currentSlide + 1) % 3)}
//           >
//             &#8250;
//           </button>
//           <div className="hero-slider-dots">
//             {[0, 1, 2].map((index) => (
//               <button
//                 key={index}
//                 className={`hero-slider-dot ${currentSlide === index ? 'active' : ''}`}
//                 onClick={() => handleSlideChange(index)}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="mobileview">
//         <img
//           onClick={handleShowModal}
//           height="100%"
//           width="100%"
//           className="d-block micro-main-slider-img ls-is-cached lazyloaded"
//           src="images/bhandup/lntevaramob.jpeg"
//           alt="L&T Evara Heights Thane Mobile Banner"
//         />
//       </div>
//     </div>
//   );
// };

// export default Hero; 





























// import React, { useState, useEffect } from "react";
// import { useGlobalContext } from "./context/globalContext";

// const Hero = () => {
//   const { handleShowModal } = useGlobalContext();
//   const [currentSlide, setCurrentSlide] = useState(0);

//   // Array of slider images
//   const sliderImages = [
//     {
//       src: "images/bhandup/lntevaraheightsweb.jpeg",
//       alt: "L&T Evara Heights Thane Web Banner 1",
//     },
//     {
//       src: "images/bhandup/lntevaraheightsweb2.jpeg",
//       alt: "L&T Evara Heights Thane Web Banner 2",
//     },
//   ];

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev + 1) % sliderImages.length);
//   };

//   const handleSlideChange = (index) => {
//     // Only allow moving forward
//     if (index > currentSlide || (currentSlide === sliderImages.length - 1 && index === 0)) {
//       setCurrentSlide(index);
//     }
//   };

//   useEffect(() => {
//     const timer = setInterval(nextSlide, 4000);
//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <div id="home" className="section">
//       <div className="desktopview">
//         <div className="hero-slider-container">
//           <div className="hero-slider-track" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
//             {sliderImages.map((image, index) => (
//               <img
//                 key={index}
//                 onClick={handleShowModal}
//                 height="100%"
//                 width="100%"
//                 className="hero-slider-image"
//                 src={image.src}
//                 alt={image.alt}
//                 style={{ backgroundColor: image.bg }}
//               />
//             ))}
//           </div>
//           <div className="hero-slider-dots">
//             {sliderImages.map((_, index) => (
//               <button
//                 key={index}
//                 className={`hero-slider-dot ${currentSlide === index ? 'active' : ''}`}
//                 onClick={() => handleSlideChange(index)}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="mobileview">
//         <img
//           onClick={handleShowModal}
//           height="100%"
//           width="100%"
//           className="d-block micro-main-slider-img ls-is-cached lazyloaded"
//           src="images/bhandup/lntevaramob.jpeg"
//           alt="L&T Evara Heights Thane Mobile Banner"
//         />
//       </div>
//     </div>
//   );
// };

// export default Hero;